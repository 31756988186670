







import { Component, Prop, Vue } from 'vue-property-decorator';
import FillInBase from './fillInBaseClass';
import UserInputString from '@/base-course/Components/UserInput/UserInputString.vue';

@Component({
  components: {
    UserInputString,
  },
})
export default class FillInInput extends FillInBase {
  @Prop({
    required: true,
  })
  public text: string;

  public inputType: 'text' | 'radio';

  private get radioType() {
    return this.text.split('||').length > 1;
  }

  private created() {
    console.log(`fillinCreated w/ text: ${this.text}`);
    this.text = this.text.substring(2);
    this.text = this.text.substring(0, this.text.length - 2);
    console.log(`fillin text trimmed to: ${this.text}`);

    const split = this.text.split('||');
    if (split.length > 1) {
      this.inputType = 'radio';
    }
  }
}
