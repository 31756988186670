





























































import { getTag, updateTag } from '@/db/courseDB';
import { DocType, Tag } from '@/db/types';
import { Status } from '@/enums/Status';
import SkldrVue from '@/SkldrVue';
import { Component, Prop } from 'vue-property-decorator';
import { alertUser } from '../SnackbarService.vue';
import CourseCardBrowser from './CourseCardBrowser.vue';

@Component({
  components: { CourseCardBrowser },
})
export default class TagInformation extends SkldrVue {
  @Prop({ required: true }) _id: string = '';
  @Prop({ required: true }) _courseId: string = '';

  public $refs: {
    snippetEditor: HTMLInputElement;
    wikiEditor: HTMLInputElement;
  };

  public snippetModel: string = '';
  public editingSnippet: boolean = false;
  public snippetSaving: boolean = false;

  public wikiModel: string = '';
  public editingWiki: boolean = false;
  public wikiSaving: boolean = false;

  public tag: Tag = {
    course: this._courseId,
    name: this._id,
    snippet: '',
    wiki: '',
    taggedCards: [],
    docType: DocType.TAG,
  };

  public editSnippet() {
    console.log('EditSnip');
    this.editingSnippet = true;
    this.$refs.snippetEditor.focus(); // not doing anything
  }
  public editWiki() {
    console.log('EditWiki');
    this.editingWiki = true;
    this.$refs.wikiEditor.focus(); // not doing anything
  }

  public async saveSnippet() {
    this.snippetSaving = true;

    const update = await updateTag({
      ...this.tag,
      snippet: this.snippetModel,
    });

    if (update.ok) {
      console.log('OK');
      // update local copy
      this.tag.snippet = this.snippetModel;
      alertUser({
        text: `Updated applied - thanks!`,
        status: Status.ok,
      });
    } else {
      alertUser({
        text: `error in applying update!`,
        status: Status.error,
      });
    }

    // leave edit-mode
    this.editingSnippet = false;
    this.snippetSaving = false;
  }

  public async saveWiki() {
    this.wikiSaving = true;

    const update = await updateTag({
      ...this.tag,
      wiki: this.wikiModel,
    });

    if (update.ok) {
      // update local copy
      this.tag.wiki = this.wikiModel;
      alertUser({
        text: `Updated applied - thanks!`,
        status: Status.ok,
      });
    } else {
      alertUser({
        text: `error in applying update!`,
        status: Status.error,
      });
    }

    // leave edit-mode
    this.editingWiki = false;
    this.wikiSaving = false;
  }

  public cancelEditSnippet() {
    console.log('Cancelling EditSnip');
    this.editingSnippet = false;
    // this.snippetModel = 'test';
    this.snippetModel = this.tag.snippet;
  }
  public cancelEditWiki() {
    this.editingWiki = false;
    this.wikiModel = this.tag.wiki;
  }

  private async created() {
    this.tag = await getTag(this._courseId, this._id);
    this.snippetModel = this.tag.snippet;
    this.wikiModel = this.tag.wiki;
  }
}
