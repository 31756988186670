

































































import { Component, Vue } from 'vue-property-decorator';
import SkTagsInput from '@/components/Edit/TagsInput.vue';
import DataInputForm from '../components/Edit/ViewableDataInputForm/DataInputForm.vue';
import MediaUploader from '@/components/Edit/ViewableDataInputForm/FieldInputs/MediaUploader.vue';
import ImageInput from '@/components/Edit/ViewableDataInputForm/FieldInputs/ImageInput.vue';
import { BlanksCardDataShapes } from '@/courses/default/questions/fillIn/index';
import SkldrVue from '@/SkldrVue';
import FillInView from '@/courses/default/questions/fillIn/fillIn.vue';
import HeatMap from '@/components/HeatMap.vue';

@Component({
  components: {
    SkTagsInput,
    DataInputForm,
    MediaUploader,
    ImageInput,
    HeatMap,
  },
})
export default class SkTagsInputMock extends SkldrVue {
  mockCourseId: string = 'mock-course-001';
  mockCardId: string = 'mock-card-001';

  BlanksCardDataShapes = BlanksCardDataShapes;
  FillInView = FillInView;

  created() {
    return (this.$store.state.dataInputForm.shapeViews = [FillInView]);
  }
}
