


























import Vue from 'vue';
import Component from 'vue-class-component';
import SkldrVue from '../SkldrVue';
import SkldrMouseTrap, { HotKeyMetaData } from '../SkldrMouseTrap';

@Component({})
export default class SkldrControlsView extends SkldrVue {
  public commands: HotKeyMetaData[] = [];
  public display: boolean = false;

  created() {
    setInterval(this.refreshState, 500);
  }

  refreshState() {
    // console.log(`this.display: ${this.display}`);
    this.commands = SkldrMouseTrap.commands;
    this.display = this.commands.length > 0;
  }
}
