















































































import moment from 'moment';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import TeacherClassroomDB, { AssignedContent, AssignedTag } from '@/db/classroomDB';
import { getCourseList, getCourseTagStubs } from '@/db/courseDB';
import { Tag } from '@/db/types';
import { ClassroomConfig, CourseConfig } from '@/server/types';
import SkldrVue from '@/SkldrVue';

@Component({})
export default class ClassroomCtrlPanel extends SkldrVue {
  @Prop({ required: true }) private _id: string;
  private _classroomCfg: ClassroomConfig;

  private classroomDB: TeacherClassroomDB;

  private _assignedContent: AssignedContent[];
  private get _assignedCourses() {
    return this._assignedContent.filter((c) => {
      return c.type === 'course';
    });
  }
  private get _assignedTags() {
    return this._assignedContent.filter((c) => {
      return c.type === 'tag';
    }) as AssignedTag[];
  }

  private nameRules: Array<(value: string) => string | boolean> = [
    (value) => {
      const max = 30;
      if (value.length > max) {
        return `Course name must be ${max} characters or less`;
      } else {
        return true;
      }
    },
  ];

  private updatePending: boolean = true;

  private addingContent: boolean = false;
  private availableCourses: CourseConfig[] = [];
  private selectedCourse: string = '';
  private availableTags: Tag[] = [];
  private selectedTags: string[] = [];

  private async created() {
    this.classroomDB = await TeacherClassroomDB.factory(this._id);
    Promise.all([
      (this._assignedContent = await this.classroomDB.getAssignedContent()),
      (this._classroomCfg = await this.classroomDB.getConfig()),
    ]);
    console.log(`Route loaded w/ (prop) _id: ${this._id}`);
    console.log(`Config: 
    ${JSON.stringify(this._classroomCfg)}`);

    this.availableCourses = (await getCourseList()).rows.map((r) => r.doc!);

    this.updatePending = false;
  }

  @Watch('selectedCourse')
  private async getCourseTags() {
    let tags = (await getCourseTagStubs(this.selectedCourse)).rows.map((row) => row.doc!);
    this.availableTags = tags;
  }

  private async assignContent() {
    if (this.selectedTags.length === 0) {
      await this.classroomDB.assignContent({
        assignedOn: moment(),
        activeOn: moment(),
        type: 'course',
        courseID: this.selectedCourse,
        assignedBy: this.$store.state._user!.username,
      });
    } else {
      await this.selectedTags.forEach((tag) => {
        this.classroomDB.assignContent({
          assignedOn: moment(),
          activeOn: moment(),
          type: 'tag',
          courseID: this.selectedCourse,
          tagID: tag,
          assignedBy: this.$store.state._user!.username,
        });
      });
    }

    this._assignedContent = await this.classroomDB.getAssignedContent();
    this.addingContent = false;
    this.selectedCourse = '';
    this.selectedTags = [];
    this.availableTags = [];
  }
  private async removeContent(c: AssignedContent) {
    this.classroomDB.removeContent(c);
  }

  private async submit() {
    this.updatePending = true;
  }
}
