


























import confetti from 'canvas-confetti';
import { Component, Prop } from 'vue-property-decorator';
import SkldrVue from '../SkldrVue';

@Component({})
export default class User extends SkldrVue {
  @Prop({
    required: true,
  })
  public _id: string;
  private u = this.$store.state._user!;

  public confetti: boolean = this.$store.state.config.likesConfetti;
  public darkMode: boolean = this.$store.state.config.darkMode;

  public configLanguages: {
    name: string;
    code: string;
  }[] = [
    {
      name: 'English',
      code: 'en',
    },
    {
      name: 'French',
      code: 'fr',
    },
  ];
  public selectedLanguages: string[] = [];

  updateDark() {
    this.u.setConfig({
      darkMode: this.darkMode,
    });
    this.$store.state.config.darkMode = this.darkMode;
  }

  updateConfetti() {
    console.log(`Confetti updated...`);
    this.u.setConfig({
      likesConfetti: this.confetti,
    });
    this.$store.state.config.likesConfetti = this.confetti;

    if (this.$store.state.config.likesConfetti) {
      confetti({
        origin: {
          x: 0.5,
          y: 1,
        },
      });
    }
  }

  public get isNewUser(): boolean {
    return this.$route.path.endsWith('new');
  }

  created() {
    this.configLanguages.forEach(l => {
      console.log(`afweatifvwzeatfvwzeta` + l.name);
    });
  }
}
